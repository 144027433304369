<template>
  <div class="main_container">
    <div class="col-12">
      <span>Audits ></span>
    </div>
    <h2 class="page_title text-center">Détails audit</h2>
    <div class="inner_container">
      <div v-if="loaded==true"
           class="tab_holder">
        {{ singleAudit.audit }}
      </div>
      <div v-else
           class="tab_holder text-center">
        Chargement des données...
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
export default {
  name: "DetailsAudit",
  data: () => ({
    user: "",
    baseUrl: process.env.VUE_APP_UPLOAD,
    pIndex: 1,
    long: "",
    loaded: false,
    goodUrl: ""
  }),
  watch: {
    singleAudit() {
      if (this.singleAudit.audit) {
        this.loaded = true
      }
    }
  },
  created() {
    this.getAudit(this.$route.params.id)
  },
  computed: {
    ...mapGetters(["singleAudit", "singleAuditErrors"])
  },
  methods: {
    ...mapActions(["getAudit"]),
    ...mapMutations(["setSingleAudit", "setAuditErrors"]),
    onFormChange() {
      this.form.validate()
    },
    launchUpdate(id) {
      this.$router.push({ name: "Detail", params: { id: id } })
    }
  }
}
</script>
